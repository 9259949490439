import React, { useEffect } from "react"
import { eventStartDate } from "../Constants";

const calculateTimeLeft = (date: Date) => {
    let timeLeft = "";
    const now = new Date().getTime();
    const distance = date.getTime() - now;
    let days = Math.floor(distance / (1000 * 60 * 60 * 24)).toString();
    days = days.length === 1 ? `0${days}` : days;
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString();
    hours = hours.length === 1 ? `0${hours}` : hours;
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString();
    minutes = minutes.length === 1 ? `0${minutes}` : minutes;
    let seconds = Math.floor((distance % (1000 * 60)) / 1000).toString();
    seconds = seconds.length === 1 ? `0${seconds}` : seconds;
    timeLeft = `${days}:${hours}:${minutes}:${seconds}`;
    return timeLeft;
}

const calculateTimeLeft_2 = (date: Date) => {
    let timeLeft = "";
    const now = new Date().getTime();
    const distance = date.getTime() - now;
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString();
    hours = hours.length === 1 ? `0${hours}` : hours;
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString();
    minutes = minutes.length === 1 ? `0${minutes}` : minutes;
    let seconds = Math.floor((distance % (1000 * 60)) / 1000).toString();
    seconds = seconds.length === 1 ? `0${seconds}` : seconds;
    timeLeft = `${hours}:${minutes}:${seconds}`;
    return timeLeft;
}

const useTimeToEvent = () => {
    const [timeLeft, setTimeLeft] = React.useState<string>(calculateTimeLeft(eventStartDate));

    useEffect(() => {
        setInterval(() => {
            setTimeLeft(calculateTimeLeft(eventStartDate));
        }, 1000)
    }, [])

    return timeLeft;
}

export const useTimeToDate = (date: Date) => {
    const [timeLeft, setTimeLeft] = React.useState<string>(calculateTimeLeft_2(date));

    useEffect(() => {
        setInterval(() => {
            setTimeLeft(calculateTimeLeft_2(date));
        }, 1000)
    }, [])

    return timeLeft;
}

export default useTimeToEvent;