
export const AllEventsData: { [key: string]: any } = {
    hackarena1_0: {
        finished: true,
        banner: {
            title: "HackArena 1.0",
            date: "02.06.2024 na SGGW w Warszawie",
            description:
                'HackArena 1.0 to pierwsza edycja hackathonu organizowanego przez Koło Naukowe "init". W wydarzeniu wzięło udział 15 osób, które stworzyły 6 botów.',
        },
        highlightInfo: "Dziękujemy za udział wszystkim uczestnikom",
        task: {
            title: "Zadanie",
            description:
                "Drużyny dostały zadanie stworzenie bota do gry typu <b>Bomberman.</b> Do wykonania zadania należało wykorzystać <b>język Python</b> i należało się zmieścić w czasie <b>8 godzin.</b>",
        },
        results: {
            title: "Wyniki",
            description: "Podium pierwszej edycji HackAreny",
            firstPlace: "Pierwsze miejsce",
            secondPlace: "Drugie miejsce",
            teams: {
                firstPlace: "Wild Pythons",
                secondPlace: "Essanse of Code",
            },
        },

        photos: {
            title: "Zdjęcia",
            list: [
                // getAPIOrigin() + '/HackArena1/DSC_6107',
                // getAPIOrigin() + '/HackArena1/DSC_6131',
                // getAPIOrigin() + '/HackArena1/DSC_6132',
                // getAPIOrigin() + '/HackArena1/DSC_6144',
                // getAPIOrigin() + '/HackArena1/DSC_6158',
                // getAPIOrigin() + '/HackArena1/DSC_6165',
                // getAPIOrigin() + '/HackArena1/DSC_6172',
                // getAPIOrigin() + '/HackArena1/DSC_6173',
                // getAPIOrigin() + '/HackArena1/DSC_6184',
                // getAPIOrigin() + '/HackArena1/DSC_6187',
                // getAPIOrigin() + '/HackArena1/DSC_6190',
                // getAPIOrigin() + '/HackArena1/DSC_6222',
                // getAPIOrigin() + '/HackArena1/DSC_6226',
                // getAPIOrigin() + '/HackArena1/DSC_6228',
                // getAPIOrigin() + '/HackArena1/DSC_6235',
                // getAPIOrigin() + '/HackArena1/DSC_6242',
                // getAPIOrigin() + '/HackArena1/DSC_6245',
                // getAPIOrigin() + '/HackArena1/DSC_6248',
                // getAPIOrigin() + '/HackArena1/DSC_6251',
                // getAPIOrigin() + '/HackArena1/DSC_6268',
                // getAPIOrigin() + '/HackArena1/DSC_6284',
                // getAPIOrigin() + '/HackArena1/DSC_6294',
                // getAPIOrigin() + '/HackArena1/DSC_6588',
                // getAPIOrigin() + '/HackArena1/DSC_6590',
                // getAPIOrigin() + '/HackArena1/DSC_6597',
                // getAPIOrigin() + '/HackArena1/DSC_6605',
                // getAPIOrigin() + '/HackArena1/DSC_6606',
                // getAPIOrigin() + '/HackArena1/DSC_6608',
                // getAPIOrigin() + '/HackArena1/DSC_6613',
                // getAPIOrigin() + '/HackArena1/DSC_6623',
                // getAPIOrigin() + '/HackArena1/DSC_6629',
                // getAPIOrigin() + '/HackArena1/DSC_6631',
                // getAPIOrigin() + '/HackArena1/DSC_6638',
                // getAPIOrigin() + '/HackArena1/DSC_6639',
                // getAPIOrigin() + '/HackArena1/DSC_6640',
                // getAPIOrigin() + '/HackArena1/DSC_6642',
                // getAPIOrigin() + '/HackArena1/DSC_6645',
                // getAPIOrigin() + '/HackArena1/DSC_6650',
                // getAPIOrigin() + '/HackArena1/DSC_6652',
                // getAPIOrigin() + '/HackArena1/DSC_6654',
                // getAPIOrigin() + '/HackArena1/DSC_6664',
                // getAPIOrigin() + '/HackArena1/DSC_6669'
                "/Assets/HackArena1/DSC_6107",
                "/Assets/HackArena1/DSC_6131",
                "/Assets/HackArena1/DSC_6132",
                "/Assets/HackArena1/DSC_6144",
                "/Assets/HackArena1/DSC_6158",
                "/Assets/HackArena1/DSC_6165",
                "/Assets/HackArena1/DSC_6172",
                "/Assets/HackArena1/DSC_6173",
                "/Assets/HackArena1/DSC_6184",
                "/Assets/HackArena1/DSC_6187",
                "/Assets/HackArena1/DSC_6190",
                "/Assets/HackArena1/DSC_6222",
                "/Assets/HackArena1/DSC_6226",
                "/Assets/HackArena1/DSC_6228",
                "/Assets/HackArena1/DSC_6235",
                "/Assets/HackArena1/DSC_6242",
                "/Assets/HackArena1/DSC_6245",
                "/Assets/HackArena1/DSC_6248",
                "/Assets/HackArena1/DSC_6251",
                "/Assets/HackArena1/DSC_6268",
                "/Assets/HackArena1/DSC_6284",
                "/Assets/HackArena1/DSC_6294",
                "/Assets/HackArena1/DSC_6588",
                "/Assets/HackArena1/DSC_6590",
                "/Assets/HackArena1/DSC_6597",
                "/Assets/HackArena1/DSC_6605",
                "/Assets/HackArena1/DSC_6606",
                "/Assets/HackArena1/DSC_6608",
                "/Assets/HackArena1/DSC_6613",
                "/Assets/HackArena1/DSC_6623",
                "/Assets/HackArena1/DSC_6629",
                "/Assets/HackArena1/DSC_6631",
                "/Assets/HackArena1/DSC_6638",
                "/Assets/HackArena1/DSC_6639",
                "/Assets/HackArena1/DSC_6640",
                "/Assets/HackArena1/DSC_6642",
                "/Assets/HackArena1/DSC_6645",
                "/Assets/HackArena1/DSC_6650",
                "/Assets/HackArena1/DSC_6652",
                "/Assets/HackArena1/DSC_6654",
                "/Assets/HackArena1/DSC_6664",
                "/Assets/HackArena1/DSC_6669",
            ],
        },
    },
    hackarena2_0: {
        finished: false,
        banner: {
            title: "HackArena 2.0",
            date: "26-27.10.2024 na SGGW w Warszawie",
            description:
                'Druga edycja hackathonu organizowanego przez Koło Naukowe "init". W tej edycji weźmie udział aż 16 drużyn po 3 osoby każda, a na zwycięzców czekają nagrody o łącznej wartości 6000 zł.',
        },
        clock: "Początek rejestracji od 01.09.2024",
        generalInformation: {
            title: "Informator",
            description:
                "W wydarzeniu weźmie udział <b>16 zespołów</b>, każdy składający się z <b>2 lub 3 osób.</b> Wydarzenie będzie trwało przez <b>dwa dni</b> na SGGW w Warszawie na Auli Kryształowej <b>(ul. Nowoursynowska 166).</b> W ciągu dnia zostaną udostępnione sale oraz będzie zapewnione jedzenie, ale <b>nie organizujemy noclegu dla uczestników.</b>",
        },
        task: {
            title: "Zadanie",
            description:
                "Waszym zadaniem będzie <b>stworzenie bota</b> do gry, która zostanie ujawniona w dniu rozpoczęcia HackAreny. W maksymalnie <b>3 osobowych grupach</b> będziecie mieli 2 dni na implementację algorytmu zdolnego do samodzielnego przeprowadzenia rozgrywki. Swoje rozwiązanie będzicie mogli napisać w <b>Python, Java, C#, C++, JavaScript, TypeScript, Go i Rust.</b> Na koniec wydarzenia zostanie przeprowadzony <b>turniej, który wyłoni zwycięską drużynę.</b>",
        },
        format: {
            title: "Format turnieju",
            description:
                "Turniej zostanie rozegrany <b>w trzech rundach.</b> W każdej rundzie będziecię się zmagali w <b>pojedynku 1v1v1v1</b> - wasz bot przeciwko trzem innym. W pierwszym etapie, przydział do poszczególnych meczów będzie roztrzygnięty poprzez <b>losowanie.</b> Następnie w celu przejścia do kolejnych faz, wasz bot musi się znaleźć w <b>top 2 aktualnie rozgrywanego starcia.</b>  W wielkim finale, spośród 4 finalistów, zostanie wyłoniony <b>mistrz HackAreny 2.0.</b>",
        },
        agenda: {
            title: "Agenda",
            schedule: {
                day1: [
                    {
                        time: "9:00",
                        event: "Rejestracja"
                    },
                    {
                        time: "10:00",
                        event: "Oficjalne rozpoczęcie"
                    },
                    {
                        time: "11:00",
                        event: "Początek pracy"
                    },
                    {
                        time: "14:00",
                        event: "Lunch (catering)"
                    },
                    {
                        time: "18:00",
                        event: "Obiad (pizza)"
                    },
                    {
                        time: "20:00",
                        event: "Próbny turniej"
                    },
                    {
                        time: "22:00",
                        event: "Koniec pierwszego dnia"
                    }
                ],
                day2: [
                    {
                        time: "8:00",
                        event: "Start drugiego dnia"
                    },
                    {
                        time: "11:00",
                        event: "Lunch (catering)"
                    },
                    {
                        time: "15:00",
                        event: "Obiad (pizza)"
                    },
                    {
                        time: "18:00",
                        event: "Zakończenie pracy, przesłanie botów"
                    },
                    {
                        time: "19:00",
                        event: "Turniej finałowy"
                    }
                ]
            }
        },
        faq: {
            title: "FAQ",
            questions: [
                {
                    question: "Czym jest hackathon?",
                    answer:
                        "Hackathon to wydarzenie, na którym w określonym czasie musisz wykonać podane zadanie programistyczne. W naszym przypadku będzie to stworzenie bota do gry.",
                },
                {
                    question: "Czy mogę przyjść sam?",
                    answer:
                        "Nie, dopuszczamy jedynie zespoły składające się z 2 lub 3 osób",
                },
                {
                    question: "Gdzie odbędzie się HackArena?",
                    answer:
                        "HackArena odbędzie się w Auli Kryształowej kampusu SGGW w Warszawie. Adres: ul. Nowoursynowska 166, budynek 9, Warszawa.",
                },
                {
                    question: "Czy będzie zapewnione wyżywienie?",
                    answer:
                        "Tak, zapewniamy podstawowe wyżywienie. Przez cały czas trwania HackAreny będzie dostępna woda, drobne przekąski oraz dwa posiłki (śniadanie oraz pizza). Jeżeli jesteś wege, zaznacz odpowiednią opcję w formularzu rejestracyjnym.",
                },
                {
                    question: "Czy muszę przynieść własny komputer?",
                    answer:
                        "Tak, każdy uczestnik musi przynieść własny komputer. Zapewniamy jedynie miejsce do pracy, zasilanie oraz internet.",
                },

                {
                    question: "Czy mogę przyjść z gotowym projektem?",
                    answer: "Nie, projekty muszą być tworzone od zera.",
                },
            ],
        },
    },
};