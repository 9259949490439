import { eventEndDate, Page, useTimeToDate } from "../../Library"
import "./Timer.css"

const Timer = () => {
    const timer = useTimeToDate(eventEndDate);

    return (
        <div className="timer">
            <h3>Do końca zostało:</h3>
            <h1>
                {timer}
            </h1>
        </div>
    )
}

export default Timer;